<template>
    <ODataLookup :data-object="dsObjectTypes" :whereClause="getWhereClause" reloadOnWhereClauseChange>        
        
        <template #toolbarActions>
            <div class="form-check" v-if="props.IdPath">
                <input class="form-check-input" id="otLookup_RestrictToContext" type="checkbox" v-model="restrictedToContext" @click="toggleContext" >
                <label class="form-check-label" for="otLookup_RestrictToContext">{{ $t("Restrict to Context") }}</label>
            </div>
            <slot name="toolbarActions"></slot>
        </template>
        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.objecttype" :ref="scope.target" style="cursor: pointer;">
                <slot name="objecttype"></slot>
            </span>
        </template>
        
        
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="300"></OColumn>

    </ODataLookup>
</template>

<script setup>
    import { ref, defineProps, computed } from 'vue';
    import { ODataLookup } from 'o365-datalookup';


    const restrictedToContext = ref(true);
    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsObjectTypes = $getDataObjectById("dsAssets_ObjectTypesLookup");
    
    const getWhereClause = computed( () => {
        var vFilter = [];

        if (props.IdPath && restrictedToContext.value) {
            vFilter.push("'" + props.IdPath + "' LIKE IdPath + '%'");
        } 

        return vFilter.join(" AND ");
    });
        

    function toggleContext(e,o) {
        restrictedToContext.value = !restrictedToContext.value;
    }
    
</script>